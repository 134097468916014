.main-item{
    font-weight: bold;
}

.main-sub-item{
    font-weight: bold;
    margin-left: 20px;
}

.sub-item{
    margin-left: 20px !important;
}

.sub-sub-item{
    margin-left: 40px !important;
}
