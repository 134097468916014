html, body{
  overflow-y: auto !important;
  scrollbar-width: thin;
  scrollbar-color: #fff #fff;
}

.txt-primary{
  color:#007bff !important;
}
.txt-danger{
  color: #dc3545 !important;
}


.table.table-ellipsis tbody td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
  }
  
  .table.table-ellipsis tbody td:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
  }

  .flag-row{
    background-color: rgba(204, 204, 255, 0.5)  !important;
    color: #000000 !important;
  }
  
  .warn-row{
    background-color:#fbdec2  !important;
    color: #000000 !important;
  }
  
  .flag-row a{
    color:#000000;
    font-weight: bold;
  }
  .small-caps{
    font-size: 11px !important;
  }
  .tx-12{
    font-size: 12px !important;
  }

  .overflow-auto{
    scrollbar-width:thin;
    scrollbar-color: #fff #fff;
    overflow: auto;
  }

  .h-200{
    max-height: 300px !important;
    min-height: 290px !important;
  }

  .hot-tr{
    cursor: pointer;
  }
  
  .hot-tr:hover{
    background-color: #8760fb;
    color:#FFFFFF
  }
  
  .hot-tr:hover a{
    color:#FFFFFF !important;
    font-weight: bold;
  }
  
  .h-300{
    max-height: 550px !important;
  }

  .hide{
    display: none !important;
  }

  .button-listing{
    list-style: none;
    display:inline;
  }

  .button-listing li{
    padding-left: .5rem;
    float: left;
  }



   /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-text{
  color: #FFFFFF;
  padding: 3px;
  display: block;
  font-size: 14px;
}

input:checked + .slider {
  background-color: #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

.table{
  font-size: 14px;
}

.link{
  cursor: pointer;
  color:#007bff;
}

.checklist-link{
  display: block;
  /* padding: 0.4rem 1rem; */
}

.list-description{
  list-style: none;
  padding: 0;
}

.list-description li{
  margin: auto;
  display: inline;
  padding-right: 5px;
}

.login-page-custom{
  background-color: #0069ff;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
  overflow: auto;
}
.login-ad{
  color: #FFF;
  margin:100px
}

.login-ad h1{
  font-size: 50px;
  font-weight: bold;
}
.functions-list{
  font-size: 28px;
  list-style-type: '\2714';
  padding-left: 20px;
}

.login-logo-custom{
  color:#000; 
  font-weight:bolder;
  text-align: center;
}

/******** BEGIN SCROLL BARS *********/
* {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #ffffff;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/*ENDSSCROLL BARS**************/

.products-list .product-info {
  margin-left: 15px !important;
}

.callout.callout-info {
  border-left-color: #477CFE !important;
}

.card-sub-title {
  font-size:13px;
	margin-top: -4px;
}





/* ----- NEW CSS ------ */

.table-header {
  display: flex;
  box-sizing: border-box !important;
  /* -ms-flex-align: center; */
  align-items: center !important;
  /* -ms-flex-wrap: wrap; */
  justify-content: space-between;
  margin: 0 !important;
  /* border: 1px solid red; */
}

.titles {
  /* border: 1px solid blue; */
  padding: 0 !important;
  padding: 0 !important;
}

.card .card-title {
  font-size: 1rem !important;
  font-weight: 500;
  /* border: 1px solid yellow; */
}

.buttons {
  /* border: 1px solid yellow; */
  margin: 0 !important;
  padding: 0 !important;
}

.card .card-description {
  color: rgb(118, 131, 143) !important;
  font-size: 0.79rem !important;
  padding: 0 !important;
  /* border: 1px solid yellow; */
  line-height: 1.3rem !important;
}

.see-all-btn {
  margin: 0 !important;
}

.task-icon-user {
  padding: 2px !important;
  border-radius: 50%;
  width: 9px !important;
  height: 9px !important;
}

.notify-icon-user {
  /* padding: 1px !important; */
  border-radius: 100%;
  width: 17px !important;
  height: 17px !important;
}

.unread-icon {
  position: absolute;
  bottom: 20px !important;
  left: 25px !important;
}

.notify-number {
  margin-bottom: 10px !important;
}

.notification-media {
  align-items: center !important;
}
