 /* Remove default bullets */
 ul, #main-tree {
    list-style-type: none;
  }
  
  /* Remove margins and padding from the parent ul */
  #main-tree {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  /* Style the caret/arrow */
  .tree-stem {
    cursor: pointer;
    font-weight: bold;
    user-select: none; /* Prevent text selection */
  }
  
  /* Create the caret/arrow with a unicode, and style it */
  .tree-stem::before {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    color:#343a40;
    display: inline-block !important;
    margin-right: 6px !important;
  }
  
  /* Rotate the caret/arrow icon when clicked on (using JavaScript) */
  .tree-stem-down::before {
    transform: rotate(90deg);
  }
  
  /* Hide the tree-branch list */
  .tree-branch {
    display: none;
    margin-left: 0px;
    margin-top: 20px;
  }
  
  /* Show the tree-branch list when the user clicks on the caret/arrow (with JavaScript) */
  .active-branch-node {
    display: block !important;
  } 

  .tree-table{
      display: table;
  }

  .tree-row{
      list-style: none;
      /* display: table-row; */
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #f1f1f1 !important;
      display: flex;
      flex-direction: row;
      justify-content:flex-start;
      border-left-style: solid;
      margin:0 !important;
      padding:5px !important;
      padding-left: 15px !important;
  }

  .account-cell{
      flex:2
  }

  .description-cell{
    flex: 3;
    text-align: left;
  }

  .balance-cell{
    flex:1;
    text-align: right;
    font-weight: bold;
    font-size: 12px !important;
  }
  

  .tree-row li{
    display: table-cell;
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;

  }
  .th li{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      padding-top: 10px !important;
  }

  .long-description{
    font-size: 12px !important;
    color: green;
    width: 50%;
  }
