/*  ----- 7.4 Contacts   ----- */

@media (min-width: 768px) and (max-width: 991.98px) {
	.main-content-body-show .main-header-contacts .main-header-menu-icon {
		display: flex;
	}
	.main-content-body-show .main-content-left-contacts {
		display: block;
	}
}
.main-content-left-contacts {
	width: 100%;
	display: block;
	padding: 0;
}
.main-content-left-contacts .main-content-breadcrumb, .main-content-left-contacts .main-content-title {
	padding-left: 20px;
}
@media (min-width: 992px) {
	.main-content-left-contacts .main-content-breadcrumb, .main-content-left-contacts .main-content-title {
		padding-left: 0;
	}
	.main-content-body-contacts {
		display: block;
		overflow-y: auto;
	}
	.main-content-contacts {
		height: calc(100vh - 60px);
	}
}
@media (min-width: 768px) {
	.main-contacts-list {
		height: calc(100% - 42px);
		position: relative;
	}
}
.main-contact-label {
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 20px;
	font-weight: 500;
	font-size: 12px;
	color: #99a6b7;
	position: relative;
}
.main-contact-label::after {
	content: '';
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	border-bottom: 1px solid #e1e6f1;
	z-index: 5;
}
.main-contact-item {
	padding: 10px 20px;
	border: 1px solid transparent;
	border-right-width: 0;
	display: flex;
	position: relative;
	cursor: pointer;
}
@media (min-width: 992px) {
	.main-contact-item {
		padding: 10px 15px;
	}
}
.main-contact-item+.main-contact-item {
	margin-top: -1px;
	border-top-color: transparent;
}
.main-contact-item+.main-contact-item{
	border-top: 1px solid #e1e6f1;
}
.main-contact-item:hover, .main-contact-item:focus {
	background-color: #f1f2f9;
	border-top-color: #e1e6f1;
	border-bottom-color: #e1e6f1;
}
.main-contact-item.selected {
	z-index: 0;
	border-left-color: #e1e6f1;
	border-top-color: #e1e6f1;
	border-bottom-color: #e1e6f1;
	background-color: #f1f2f9;
}
.main-contact-body {
	flex: 1;
	margin-left: 15px;
}
.main-contact-body h6 {
	color:#334151;
	font-weight: 500;
	margin-bottom: 2px;
}
.main-contact-body span {
	font-size: 13px;
	color: #99a6b7;
}
.main-contact-star {
	font-size: 16px;
	color: #99a6b7;
}
.main-contact-star:hover, .main-contact-star:focus {
	color: #99a6b7;
}
.main-contact-star.active {
	color: #ffc107;
}
@media (min-width: 992px) {
	.main-content-body-contacts {
		overflow: hidden;
	}
}
.main-contact-info-header {
	padding-top: 40px;
	padding-left: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #e1e6f1;
	position: relative;
}

.main-contact-info-header .media {
	display: block;
}
@media (min-width: 992px) {
	.main-contact-info-header .media {
		display: flex;
		align-items: center;
	}
}
.main-contact-info-header .main-img-user {
	width: 100px;
	height: 100px;
}
.main-contact-info-header .main-img-user::after {
	display: none;
}
.main-contact-info-header .main-img-user a {
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	background-color: #e1e6f1;
	color: #99a6b7;
	font-size: 18px;
	line-height: .9;
	box-shadow: 0 0 0 2px #e1e6f1;
	border-radius: 100%;
}
.main-contact-info-header .main-img-user a:hover, .main-contact-info-header .main-img-user a:focus {
	background-color: #8760fb;
	color:#fff;
	box-shadow: 0 0 0 2px 8760fb;
}
.main-contact-info-header .media-body {
	margin-top: 30px;
}
@media (min-width: 992px) {
	.main-contact-info-header .media-body {
		margin-top: 0;
		margin-left: 30px;
	}
}
.main-contact-info-header .media-body h4 {
	color:#334151;
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 2px;
	line-height: 1;
}
.main-contact-info-header .media-body p {
	color: #596882;
	margin-bottom: 15px;
}
.main-contact-info-header .media-body .nav-link {
	padding: 0;
	display: flex;
	align-items: center;
	color: #99a6b7;
	font-size: 13px;
}
@media (min-width: 576px) {
	.main-contact-info-header .media-body .nav-link {
		font-size: 0.875rem;
	}
}
.main-contact-info-header .media-body .nav-link i {
	font-size: 18px;
	line-height: 0;
	margin-right: 5px;
}
@media (min-width: 576px) {
	.main-contact-info-header .media-body .nav-link i {
		margin-right: 7px;
	}
}
.main-contact-info-header .media-body .nav-link i.typcn {
	line-height: .9;
}
.main-contact-info-header .media-body .nav-link i.typcn::before {
	width: auto;
}
.main-contact-info-header .media-body .nav-link:hover, .main-contact-info-header .media-body .nav-link:focus {
	color: #8760fb;
}
.main-contact-info-header .media-body .nav-link+.nav-link {
	margin-left: 10px;
}
@media (min-width: 576px) {
	.main-contact-info-header .media-body .nav-link+.nav-link {
		margin-left: 25px;
	}
}
.main-contact-action {
	position: absolute;
	top: 0;
	right: 20px;
	display: flex;
}
@media (min-width: 992px) {
	.main-contact-action {
		right: 0;
	}
}
.main-contact-action a {
	display: flex;
	align-items: center;
	color: #99a6b7;
	font-size: 12px;
}
.main-contact-action a:hover, .main-contact-action a:focus {
	color: #8760fb;
}
.main-contact-info-body {
	padding-left: 1.25rem;
}
@media (min-width: 992px) {
	.main-contact-info-body {
		padding:1.25rem;
		height: calc(100% - 126px);
		position: relative;
	}
}
.main-contact-info-body .media-list {
	padding: 20px 0;
}
.main-contact-info-body .media-list {
	padding: 15px 0;
}
.main-contact-info-body .media {
	align-items: center;
	position: relative;
}
.main-contact-info-body .media+.media {
	margin-top: 15px;
}
.main-contact-info-body .media:last-child {
	margin-bottom: 15px;
}
.main-contact-info-body .media-icon {
	font-size: 24px;
	color: #99a6b7;
	line-height: 1;
	width: 40px;
	text-align: center;
}
@media (min-width: 992px) {
	.main-contact-info-body .media-icon {
		width: 100px;
	}
}
@media (min-width: 992px) {
	.main-contact-info-body .media-body {
		display: flex;
	}
}
@media (min-width: 992px) {
	.main-contact-info-body .media-body>div {
		flex: 1;
	}
}
.main-contact-info-body .media-body>div+div {
	margin-top: 15px;
}
@media (min-width: 992px) {
	.main-contact-info-body .media-body>div+div {
		margin-top: 0;
	}
}
.main-contact-info-body .media-body label {
	color: #99a6b7;
	margin-bottom: 0;
	display: block;
	font-size: 13px;
}
.main-contact-info-body .media-body span {
	display: block;
	color:#334151;
	font-weight: 500;
}



/*  ----- 7.8 Profile   ----- */

.main-content-profile {
	flex: 1;
}
@media (max-width: 991.98px) {
	.main-content-profile .container, .main-content-profile .container-fluid {
		display: block;
	}
}
.main-content-left-profile {
	padding-left: 0;
	padding-right: 0;
	display: block;
	border-bottom: 1px solid #e1e6f1;
	padding-bottom: 25px;
	width: auto;
}
@media (min-width: 992px) {
	.main-content-left-profile {
		width: 270px;
		padding-right: 20px;
		padding-bottom: 0;
		border-right: 1px solid #e1e6f1;
		border-bottom: 0;
	}
}
@media (min-width: 1200px) {
	.main-content-left-profile {
		padding-right: 25px;
	}
}
.main-profile-overview .main-img-user {
	width: 100px;
	height: 100px;
	margin-bottom: 20px;
	margin: 0 auto;
}
.main-profile-overview .main-img-user::after {
	display: none;
}
.main-profile-overview .btn-icon-list .btn, .main-profile-overview .btn-icon-list .sp-container button, .sp-container .main-profile-overview .btn-icon-list button {
	border-radius: 100%;
}
.main-profile-name {
	color:#334151;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 2px;
}
.main-profile-name-text {
	color: #99a6b7;
	font-size: 13px;
	margin-bottom: 0;
}
.main-profile-bio {
	font-size: 13px;
	margin-bottom: 20px;
}
.main-profile-social-list .media {
	align-items: center;
}
.main-profile-social-list .media+.media {
	margin-top: 20px;
}
.main-profile-social-list .media-icon {
	font-size: 34px;
	width: 30px;
	line-height: 0;
}
.main-profile-social-list .media-body {
	margin-left: 20px;
}
.main-profile-social-list .media-body span {
	display: block;
	font-size: 12px;
}
.main-profile-social-list .media-body a {
	font-size: 13px;
}
.main-content-body-profile .nav {
	flex-direction: column;
	padding: 20px 20px 20px 20px;
	border-bottom: 1px solid #e1e6f1;
}
@media (min-width: 576px) {
	.main-content-body-profile .nav {
		flex-direction: row;
		align-items: center;
		padding-bottom: 10px;
	}
}
@media (min-width: 992px) {
	.main-content-body-profile .nav {
		padding-left: 20px;
	}
}
@media (min-width: 1200px) {
	.main-content-body-profile .nav {
		padding-left: 25px;
	}
}
.main-content-body-profile .main-nav-line .nav-link {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
	color: #3b4863;
}
.main-content-body-profile .main-nav-line .nav-link.active {
	font-weight: 500;
	color: #8760fb;
}
.main-content-body-profile .main-nav-line .nav-link.active::before {
	background-color: #8760fb;
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.main-content-body-profile .main-nav-line .nav-link.active::before {
		width: auto;
		top: auto;
		left: 0;
		right: 0;
		height: 2px;
	}
}
@media (min-width: 576px) {
	.main-content-body-profile .main-nav-line .nav-link.active::before {
		bottom: -11px;
	}
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.main-content-body-profile .main-nav-line .nav-link+.nav-link {
		margin-top: 0;
		margin-left: 20px;
	}
}
.main-profile-body {
	padding: 15px 0 0;
}
@media (min-width: 576px) {
	.main-profile-body {
		padding-top: 20px;
	}
}
@media (min-width: 992px) {
	.main-profile-body {
		padding: 25px 0 0 20px;
	}
}
@media (min-width: 1200px) {
	.main-profile-body {
		padding-left: 25px;
	}
}
.main-profile-view-chart {
	position: relative;
	width: calc(100% - 10px);
	height: 200px;
}
@media (min-width: 375px) {
	.main-profile-view-chart {
		width: 100%;
	}
}
@media (min-width: 576px) {
	.main-profile-view-chart {
		height: 250px;
	}
}
.main-profile-view-info {
	position: absolute;
	top: 0;
	left: 0;
}
.main-profile-view-info h6 {
	font-size: 32px;
	font-weight: 500;
	color:#334151;
	margin-bottom: 0;
}
.main-profile-view-info span {
	font-size: 12px;
	color: #f10075;
	margin-left: 5px;
}
.main-profile-view-info p {
	font-size: 13px;
	margin-bottom: 0;
}
.main-traffic-detail-item>div:first-child {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	margin-bottom: 7px;
}
.main-traffic-detail-item>div:first-child>span:first-child {
	color: #99a6b7;
}
.main-traffic-detail-item>div:first-child>span:last-child {
	font-size: 11px;
	font-weight: 700;
	color:#334151;
}
.main-traffic-detail-item>div:first-child>span:last-child span {
	color: #99a6b7;
	font-weight: 400;
}
.main-traffic-detail-item+.main-traffic-detail-item {
	margin-top: 18px;
}
.main-profile-work-list .media+.media {
	margin-top: 25px;
}
.main-profile-work-list .media-logo {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	color: #fff;
	font-size: 21px;
	position: relative;
	top: 2px;
	border-radius: 100%;
}
.main-profile-work-list .media-body {
	margin-left: 20px;
}
.main-profile-work-list .media-body h6 {
	color:#334151;
	font-weight: 500;
	margin-bottom: 2px;
}
.main-profile-work-list .media-body span {
	display: block;
	margin-bottom: 5px;
}
.main-profile-work-list .media-body p {
	margin-bottom: 0;
	font-size: 12px;
	color: #99a6b7;
}
.main-profile-contact-list .media {
	align-items: center;
}
.main-profile-contact-list .media+.media {
	margin-top: 25px;
}
.main-profile-contact-list .media-icon {
	line-height: 0;
	font-size: 22px;
	width: 35px;
	text-align: center;
}
.main-profile-contact-list .media-body {
	margin-left: 25px;
}
.main-profile-contact-list .media-body span {
	font-size: 12px;
	color: #99a6b7;
	display: block;
	line-height: 1.3;
}
.main-profile-contact-list .media-body div {
	font-weight: 500;
	color:#334151;
}


/*  ----- 5.4 Image    ----- */

.main-img-user {
	display: block;
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
}
.main-img-user img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 100%;
}
.avatar-list .avatar {
    margin-bottom: .5rem;
}
.avatar-list .avatar:not(:last-child) {
    margin-right: .5rem;
}
.avatar-list {
    margin: 0 0 -.5rem;
    padding: 0;
    font-size: 0;
}
.avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	background-color: #8760fb;
}
.main-avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	background-color: #8760fb;
}
.main-avatar::after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 3px;
	width: 6px;
	height: 6px;
	background-color: #99a6b7;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
	border-radius: 100%;
}
.main-avatar.online::after {
	background-color: #03c895;
}
.avatar-xs {
	width: 24px;
	height: 24px;
	font-size: 11px;
}
.avatar-xs::after {
	width: 5px;
	height: 5px;
}
.avatar-sm {
	width: 32px;
	height: 32px;
	font-size: 14px;
}
.avatar-sm::after {
	width: 7px;
	height: 7px;
}
.avatar-md {
	width: 48px;
	height: 48px;
	font-size: 24px;
}
.avatar-md::after {
	width: 9px;
	height: 9px;
	right: 2px;
	bottom: 2px;
}
.avatar-lg {
	width: 64px;
	height: 64px;
	font-size: 28px;
}
.avatar-lg::after {
	width: 10px;
	height: 10px;
	bottom: 3px;
	right: 4px;
}
.avatar-xl {
	width: 72px;
	height: 72px;
	font-size: 36px;
}
.avatar-xl::after {
	width: 11px;
	height: 11px;
	bottom: 4px;
	right: 5px;
	box-shadow: 0 0 0 2.5px #fff;
}
.avatar-xxl {
	width: 100px;
	height: 100px;
	font-size: 56px;
}
.avatar-xxl::after {
	width: 13px;
	height: 13px;
	bottom: 6px;
	right: 7px;
	box-shadow: 0 0 0 3px #fff;
}

.progress{
	height: auto;
	display: flex;
	height: 0.5rem;
	overflow: hidden;
	font-size: 0.65625rem;
	background-color: #e1e6f1;
}

.progress, .progress-bar {
	border-radius: 0;
  }
